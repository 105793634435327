.App {
  text-align: center;
  word-spacing: 4px;
  letter-spacing: 0.5px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body::-webkit-scrollbar {
  width: 15px; /* width of the entire scrollbar */
}

body::-webkit-scrollbar-track {
  background: white; /* color of the tracking area */
}

body::-webkit-scrollbar-thumb {
  background-color: #000a45; /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
  border: 3px solid white; /* creates padding around scroll thumb */
}

.back-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  font-size: 2rem;
  width: 3rem;
  height: 3rem;
  color: white;
  cursor: pointer;
  border-radius: 100px;
  border: none;
  z-index: 5;
}

.back-to-top:hover {
  background: white;
}

@media (min-width: 320px) and (max-width: 480px) {
  .back-to-top {
    font-size: 1.5rem;
    width: 2.5rem;
    height: 2.5rem;
  }

  .swiper {
    width: 100% !important;
  }

  .pagination_container {
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  .swiper-pagination {
    /* background-color: #f5e5ff !important; */
    border-radius: 2rem;
    margin: auto;
    width: 30%;
    bottom: 2.5rem !important;
    justify-content: center;
    left: 3rem;
    left: 35%;
  }

  .swiper-container-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-custom,
  .swiper-pagination-fraction {
    /* bottom: 2rem !important;
    left: 1rem !important;
    width: 100% !important;
    background-color: #f5e5ff;
    border-radius: 2rem;
    margin: auto; */
  }

  .swiper-pagination-bullet {
    width: 4px;
    height: 4px;
    margin: 0 0.2rem;
  }
}

.swiper {
  height: 100%;
  width: 50%;
  overflow: hidden;
}

.swiper_yt {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
  right: 10px;
  top: 23rem;
}

.swiper-button-prev:after,
.swiper-container-rtl .swiper-button-next:after {
  /* background: url("./assets/group_434.png") no-repeat center center; */
}

.swiper-container-horizontal > .swiper-pagination-bullets,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  /* bottom: 10% !important;
  left: 2% !important; */
}

.pagination_container {
  width: 100%;
}

.swiper-pagination {
  bottom: 2rem;
}

.swiper-pagination-bullet {
  width: 5px;
  height: 5px;
  margin: 0 0.2rem;
}

.swiper-slide_yt {
  width: 100%;
  /* display: flex; */
  /* padding: 2rem 0;
  justify-content: space-between; */
}

.db_cont {
  display: flex;
}

.vieo_deck {
  margin-right: 1rem;
}

.col_cont {
  display: block;
}

.up-arraow{
  width: 1.5rem;
  height: 1rem;
}


.line
{
  width:100%;background-color: #FF6200;
  border: 0.1em solid #FF6200; /* dashed, groove, inset */
  margin-top:0.45em;margin-bottom:0.45em;
}

.line, .text
{
  float:left;
}

.text
{
  padding-left:10px;padding-right:10px;
  font-size: 1.5rem;
  font-family: "poppinsBold";
}

.something {
  display: flex;
  align-items: center;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}