.continer {
  margin: 4rem 0 0 0;
  display: flex;
  /* height: 91vh; */
  font-family: "poppinsMedium";
}

.flex1 {
  width: 40%;
}

.flex2 {
  width: 60%;
  /* height: 100%; */
  /* background: linear-gradient(to bottom right, #023E8A, #00B4D8); */
  background-color: #dbf4ff;
  padding: 1rem 0;
}
.form_title {
  font-family: "poppinsBold";
  font-size: 2rem;
}

.address_container {
  width: 100%;
  text-align: left;
  margin: auto;
  display: flex;
}

.email_container {
  display: flex;
  margin: 2rem 0;
}

.imgmg {
  width: 1.5rem;
}

.info_data {
  margin-left: 1rem;
}

.social_media_cont {
  display: flex;
  padding: 1rem 0;
}

.social_media_cont > img {
  width: 1.2rem;
  cursor: pointer;
  margin-right: 1rem;
}

.contact_container {
  width: 80%;
  margin: auto;
  transform: translateY(50%);
}

.timing_container {
  text-align: left;
  display: flex;
}

.ssss {
  margin: 4rem 0;
  text-align: left;
  margin-left: 0.5rem;
}

@media (min-width: 320px) and (max-width: 480px) {
  .flex2 {
    width: 100%;
    padding: 1rem 0;
  }
  .continer {
    display: block;
  }
  .feedbbb {
    width: 90%;
  }
  .flex1 {
    width: 100%;
    margin-top: 3rem;
  }

  .contact_container {
    width: 85%;
    display: block;
    font-size: 0.8rem;
    margin: auto;
    transform: translateY(10%);
  }
}

.fb_title {
  color: #023e8a;
  font-size: 1.5rem;
  text-align: left;
}

.fb_sub_title {
  text-align: left;
  margin: 1rem 0;
  color: grey;
}

.feedbbb {
  margin-top: 5rem;
  width: 89%;
}
