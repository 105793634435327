.container {
  /* background: linear-gradient(to bottom right,  #FF6200,  #FDA766); */
  height: 40rem;
  display: flex;
  margin-top: 5rem;
  line-height: 3rem;
  font-family: "gurajada" !important;
}

.build {
  color: #023e8a;
}

.hrooGB {
  width: 100%;
  height: 100%;
  position: absolute;
}

.explore {
  border: none;
  padding: 0rem;
  margin: 2rem 0;
  width: 9rem;
  letter-spacing: 2px;
  font-family: "gurajada";
  background-color: #023e8a;
  color: white;
  border-radius: 0.5rem;
  cursor: pointer;
  font-size: 1.5rem;
}

.explore:hover {
  background-color: #00b4d8;
}

.social_media_cont {
  display: flex;
  padding: 2rem 0;
}

.social_media_cont > img {
  width: 1.2rem;
  cursor: pointer;
  margin-right: 1rem;
}

.text3 {
  color: grey;
  font-size: 1.7rem;
  line-height: 2rem;
  width: 60%;
}

.texts_container {
  text-align: left;
  width: 60%;
}

.info_container {
  position: absolute;
  top: 45%;
  transform: translate(10%, -50%);
}

.dr_image {
  height: 70%;
  margin-right: 10rem;
  margin-top: 7rem;
  z-index: 3;
}

.img_container {
  position: relative;
}

.text1 {
  font-size: 3.5rem;
  margin: 0.5rem 0;
}

.text2 {
  font-size: 3.5rem;
  font-weight: bold;
  margin: 0.5rem 0;
}

.text3 {
  margin: 0.5rem 0;
}

.button_container {
  display: flex;
  margin-top: 2rem;
}

.button_single_container {
  margin: 0 1rem 0.5rem 0;
}

.img1 {
  position: absolute;
  width: 16rem;
  left: 20rem;
}

.img2 {
  position: absolute;
  width: 15rem;
  bottom: -1rem;
  left: 5rem;
}

.ddd {
  background-size: cover;
  top: 2rem;
  width: 100%;
  height: 100%;

  margin-top: 4rem;
}

.circle {
  height: 40rem;
  width: 40rem;
  /* background-image: -webkit-linear-gradient(135deg, #00B4D8 50%, #ffff 50%); */
  right: 0;
  position: absolute;
  bottom: -1rem;
}

@media (min-width: 320px) and (max-width: 480px) {
  .text1 {
    font-size: 2rem;
    margin: 0.5rem 0;
  }

  .text2 {
    font-size: 2rem;
    font-weight: bold;
    margin: 0.5rem 0;
  }

  .text3 {
    font-size: 1.3rem;
    line-height: 1.5rem !important;
  }

  .button_container {
    display: block;
    margin-top: 2rem;
  }

  .info_container {
    top: 50%;
    width: 100%;
    transform: none;
    text-align: center;
  }

  .text3 {
    width: 90%;
    margin: auto;
  }

  .container {
    display: block;
    position: relative;
    line-height: 2rem;
    /* //  height: 25rem; */
  }

  .img_container {
    position: static;
  }

  .dr_image {
    height: 60%;
    position: absolute;
    right: 1rem;
    bottom: 0;
    left: auto;
  }

  .img1 {
    width: 9rem;
    left: 10rem;
    height: 16rem;
  }

  .img2 {
    width: 8rem;
    bottom: 0rem;
    left: 2rem;
  }

  .explore {
    padding: 0.2rem;
    width: 6rem;
    letter-spacing: 2px;
    margin: 1.5rem 0rem;
    font-size: 1rem;
  }

  .social_media_cont {
    display: block;
  }

  .social_media_cont > img {
    width: 1rem;
  }

  .circle {
    height: 10rem;
    width: 10rem;
  }

  .m_cont {
    height: 55% !important;
  }

  .dr_image_m {
    width: 65% !important;
    top: -22rem !important;
  }
}

.dr_image_m {
  width: 60%;
  height: 70%;
  top: -16rem;
  position: relative;
}

.hrooGB_m {
  width: 100%;
  height: 100%;
  position: relative;
}

.m_cont {
  /* height: 10rem; */
  height: 40%;
}
