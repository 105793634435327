.lolll {
  display: flex;
  height: 25rem;
}

.flx1 {
  width: 40%;
}

.flx2 {
  width: 60%;
}

.dsd {
  width: 75%;
  height: 100%;
}
