.reqBocContainer {
  background-color: #dbf4ff;
  /* background: linear-gradient(90deg, rgb(0,171,200) 35%, #DBF4FF 100%); */
  width: 95%;
  margin: 2rem auto;
  display: flex;
  justify-content: center;
  display: flex;
  align-items: center;
}

.reqMsg {
  font-size: 1.5rem;
  /* font-weight: bold; */
  text-align: left;
  color: black;
  padding: 1rem 3rem;
}

.submitButton {
  border: none;
  padding: 1rem;
  margin: 2rem 0;
  width: 9rem;
  letter-spacing: 2px;
  font-family: "poppinsBold";
  background-color: #023e8a;
  color: white;
  border-radius: 0.5rem;
  cursor: pointer;
}

.semtimg {
  width: 60%;
}

@media (min-width: 320px) and (max-width: 480px) {
  .submitButton {
    padding: 0.7rem;
    width: 6rem;
    letter-spacing: 1px;
    margin: 1.5rem 0rem;
    font-size: 0.7rem;
  }

  .semtimg {
    width: 100%;
    margin-left: 0rem !important;
  }

  .reqMsg {
    font-size: 1rem;
    display: block !important;
  }
}
