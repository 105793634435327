.feature {
  display: flex;
  margin: 3rem 0;
  box-shadow: 0 0px 0px 0 rgb(0 0 0 / 20%), 0 3px 10px 0 #dbf4ff;
  border-radius: 0.5rem;
  line-height: 1.5rem;
}
.feature_img {
  width: 70%;
  /* height: 13rem; */
  /* border: 1px solid #00b4d8; */
  padding: 0.3rem;
  padding: 2rem;

  object-fit: cover;
}

.feature_img2 {
  width: 60%;
  /* height: 13rem; */
  /* border: 1px solid #00b4d8; */
  padding: 0.3rem;
  padding: 2rem;

  object-fit: cover;
}

.title {
  font-size: 2rem;
  margin: 3rem 0;
  font-family: "poppinsBold";
  color: #023e8a;
}
.feature_flex1 {
  /* width: 40%; */
}

.feature_flex2 {
  width: 60%;
  text-align: left;
  margin-left: 2rem;
  padding: 2rem;
}

.feature_title {
  font-size: 1.5rem;
  font-family: "poppinsBold";
}

.feature_description {
  margin: 1rem 0;
  color: #797d93;
  font-size: 1rem;
  font-family: "poppinsRegular";
}

.container {
  width: 80%;
  margin: 5rem auto 5rem auto;
}

.fall_backs {
  width: 10rem;
}

.mobile_view_top_img {
  display: none;
}

@media (min-width: 320px) and (max-width: 480px) {
  .feature {
    display: block;
    margin: 1rem 2rem;
    box-shadow: none;
    border-radius: none;
  }
  .feature_flex1 {
    width: 100%;
  }
  .feature_img {
    width: 80% !important;
    margin: 2rem 0 0 0 !important;
    padding: 0rem !important;
  }
  .feature_img2 {
    width: 80% !important;
    margin: 2rem 0 0 0 !important;
    padding: 0rem !important;
  }
  .container {
    width: 100%;
    margin: 0rem auto;
    padding: 2rem 0;
  }
  .feature_img {
    margin-bottom: 1rem;
    width: 90%;
  }
  .title {
    font-size: 1.5rem;
    margin: 2rem 0 3rem 0;
  }
  .feature_title {
    font-size: 1.2rem;
    font-family: "poppinsBold";
  }
  .feature_description {
    font-size: 0.9rem;
    color: #797d93;
    font-family: "poppinsRegular";
  }

  .feature_flex2 {
    text-align: left;
    margin-left: 0rem;
    width: 100%;
    padding: 0rem !important;
  }

  .mobile_view_top_img {
    display: block;
    background-color: #f9f9f9;
    height: 4rem;
    margin-bottom: 2rem;
  }
  .spclMsg {
    font-size: 1.5rem !important;
    margin-top: 2rem;
  }
  .mobile_view_top_img > img {
    width: 40%;
    height: 100%;
    object-fit: cover;
    float: right;
    width: 6rem;
  }
  .feature_flex2_data_cont {
    transform: none !important;
    -webkit-transform: none !important;
  }

  .intro_service {
    font-size: 0.9rem !important;
    width: 80% !important;
  }

  .dividerBar {
    height: 1.5rem !important;
    background-image: -webkit-linear-gradient(135deg, #00b4d8 50%, #ffff 50%) !important;
  }

  .dividerBar2 {
    height: 1.5rem !important;
    background-image: -webkit-linear-gradient(35deg, #00b4d8 50%, #ffff 50%) !important;
  }
  
}

.feature_flex2_data_cont {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
}
.spclMsg {
  font-weight: bold;
  color: #023e8a;
  font-size: 2.5rem;
  font-family: "sign";
}

.intro_service {
  width: 90%;
  margin: 2rem auto;
  text-align: left;
  line-height: 1.5rem;
  font-size: 1rem;
}

.blob {
  position: absolute;
  height: 25rem;
  z-index: -1;
  left: -5rem;
}

.dividerBar {
  height: 2rem;
  background-image: -webkit-linear-gradient(135deg, #00b4d8 60%, #ffff 50%);
}

.dividerBar2 {
  height: 2rem;
  background-image: -webkit-linear-gradient(35deg, #00b4d8 60%, #ffff 50%);
}

.right_cont {
  width: 40%;
}
