.main_heading {
  font-size: 2rem;
  font-family: "poppinsBold";
  margin: 2rem 0 1rem 0;
  color: #023e8a;
}

.sub_heading {
  width: 40%;
  margin: auto;
  color: grey;
  font-weight: bold;
}

.learning_list_container {
  display: flex;
  justify-content: space-between;
  margin: 3rem 1rem;
  flex-wrap: wrap;
}

.learning_item {
  background: white;
  width: 15%;
  height: 2.5rem;
  /* border: 1px solid #FF6200; */
  border-radius: 0.5rem;
  padding: 1rem;
  display: flex;
  margin: 0.5rem;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.learning_item_name {
  margin: auto;
}

.learning_item_icon {
  width: 3.5rem;
}

.container {
  background-color: #fafafa;
  padding: 2rem;
}

@media (min-width: 320px) and (max-width: 480px) {
  .learning_list_container {
    display: block;
  }

  .learning_item {
    width: 70%;
    padding: 0.5rem;
    margin: 1rem auto;
  }

  .main_heading {
    font-size: 1.5rem;
  }

  .container {
    margin: 0;
  }
}
