.container {
  display: flex;
}

.close {
  width: 1rem;
  position: absolute;
  right: 2rem;
  cursor: pointer;
  top: 2rem;
}

.gif {
  width: 80%;
}

.msgBlock {
  margin-top: 2rem;
  width: 60%;
}

.mainMsg {
  font-weight: bold;
  font-size: 1.3rem;
  color: #023e8a;
}

.subMsg {
  color: grey;
  font-size: 1rem;
  font-weight: bold;
  margin: 0.5rem 0;
}

@media (min-width: 320px) and (max-width: 480px) {
  .container {
    display: block;
    text-align: center;
  }

  .msgBlock {
    margin-top: 2rem;
    width: 100%;
  }

  .close {
    right: 1rem;
    top: 1rem;
  }
}
