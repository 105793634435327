.container {
  /* background: #fafafa; */
  background: linear-gradient(90deg,rgb(0,217,187)  0%, rgb(0,171,200) 35%, #DBF4FF 100%);
  height: auto;
  width: 100%;
  text-align: left;
}

.logo {
  width: 20rem;
  object-fit: contain;
}

.inner_container {
  padding: 2rem;
}

.extraInfo {
  margin-top: 3rem;
}

.social_media_cont {
  display: flex;
  padding: 1rem 0;
}

.social_media_cont > img {
  width: 1.2rem;
  cursor: pointer;
  margin-right: 1rem;
}

.imgmg {
  width: 1rem;
}

.top {
  display: flex;
  justify-content: space-between;
}
.email_container {
  display: flex;
  margin: 0.5rem 0;
}

.info_data {
  margin-left: 1rem;
  margin-bottom: 1rem;
}

@media (min-width: 320px) and (max-width: 480px) {
  .top {
    display: block !important;
  }

  .ssss {
    margin-top: 3rem;
  }

  .logo {
    width: 14rem !important;
  }

  .container {
    background: linear-gradient(90deg,rgb(0,217,187)  35%, rgb(0,171,200) 100%) !important;
  }
}
