.formContainer {
  width: 80%;
  margin: auto;
  text-align: left;
  border-radius: 1rem;
  height: 80%;
  font-family: "poppinsMedium";
  transform: translate(0%, 20%);
}

.label {
  display: block;
  margin-bottom: 0.5rem;
}

.input {
  display: block;
  width: 95% !important;
  padding: 0.5rem;
  border-radius: 0.3rem;
  border: 1px solid #cccccc;
  font-family: "poppinsMedium";
}

.formTitle {
  text-align: center;
  /* font-weight: bold; */
  font-size: 1.3rem;
  color: #023e8a;
}

.submitButton {
  font-family: "poppinsMedium";
  width: 40%;
  padding: 0.7rem;
  border: none;
  cursor: pointer;
  background-color: #023e8a;
  color: white;
  border-radius: 0.5rem;
}

form {
  margin: 2rem auto;
  width: 60%;
  padding: 2rem;
  background-color: white;
  border-radius: 1rem;
}

.fieldContainer {
  margin: 1rem 0;
}

/* select {
  font-family: "poppinsMedium";
} */

@media (min-width: 320px) and (max-width: 480px) {
  .formContainer {
    width: 100%;
    transform: none;
  }

  form {
    width: 70%;
  }

  .label {
    font-size: 0.9rem;
  }
  .formTitle {
    margin-bottom: 3rem;
  }
}


.errorMsg {
  color: red;
  margin: 0.5rem 0;
  font-size: 0.9rem;
}