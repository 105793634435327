.description {
  width: 70%;
  margin: 3rem auto;
  line-height: 1.5rem;
}

.header {
  margin-top: 1rem;
  /* font-weight: bold; */
  font-size: 2.5rem;
  color: #023e8a;
  font-family: "poppinsBold";
}

.first_image {
  position: absolute;
  top: 1rem;
  right: 6rem;
  padding: 1.5rem;
  width: 3rem;
  border-radius: 1.5rem;
  box-shadow: 0 5px 10px rgba(154, 160, 185, 0.05),
    0 15px 40px rgba(166, 173, 201, 0.2);
}

.sec_image {
  position: absolute;
  top: 12rem;
  right: 2rem;
  width: 3rem;
  padding: 1.5rem;
  border-radius: 1.5rem;
  box-shadow: 0 5px 10px rgba(154, 160, 185, 0.05),
    0 15px 40px rgba(166, 173, 201, 0.2);
}

.third_image {
  position: absolute;
  top: 22rem;
  right: 6rem;
  width: 3rem;
  padding: 1.5rem;
  border-radius: 1.5rem;
  box-shadow: 0 5px 10px rgba(154, 160, 185, 0.05),
    0 15px 40px rgba(166, 173, 201, 0.2);
}

.container {
  position: relative;
  padding: 5rem 0;
  background-color: #fafafa;
  /* background-image: url("../../assets/Reading glasses.gif"); */
  /* height: 10rem;
  background-size: contain;
  background-repeat: no-repeat; */
}

.description > * {
  margin: 2rem 0;
  text-align: initial;
}

.img1 {
  position: absolute;
  left: 5rem;
  width: 18rem;
  height: 25rem;
  top: -3rem;
}

.img2 {
  position: absolute;
  left: 5rem;
  width: 18rem;
  top: 16rem;
}

@media (min-width: 320px) and (max-width: 480px) {
  .header {
    font-size: 1.5rem;
  }
  .description {
    width: 80%;
  }
  .first_image,
  .sec_image,
  .third_image {
    display: none;
  }
  .container {
    /* margin-top: 4rem; */
    padding: 2rem 0.5rem;
  }
  .description {
    width: 80%;
    margin: 2rem auto;
    font-size: 0.9rem;
  }
  .img1 {
    left: 2rem;
    width: 10rem;
    top: 0;
    height: 16rem;
  }
  .img2 {
    display: none;
  }
  .icon1 {
    width: 2.5rem !important;
    left: 2rem !important;
  }
  .icon2 {
    width: 2rem !important;
    left: 2rem !important;
    display: none !important;
  }
  .icon3 {
    width: 2.5rem !important;
    right: 2rem !important;
    top: 4rem !important;
  }
  .icon4 {
    width: 2.5rem !important;
    bottom: 1rem !important;
    right: 3rem !important;
  }
}

.icon1 {
  width: 3rem;
  position: absolute;
  left: 8rem;
}

.icon2 {
  width: 3rem;
  position: absolute;
  left: 25rem;
  bottom: 1rem;
}
.icon3 {
  width: 3rem;
  position: absolute;
  right: 20rem;
  top: 2rem;
}
.icon4 {
  width: 3rem;
  position: absolute;
  right: 6rem;
  bottom: 7rem;
}
