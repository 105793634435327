.header {
  border-bottom: 1px solid #e2e8f0;
  position: fixed;
  overflow: hidden;
  /* background-color: #666666; */
  top: 0;
  width: 100%;
  z-index: 10;
  background-color: white;
  z-index: 50;
  letter-spacing: 1px;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
}

.hamburger {
  display: none;
}

.bar {
  display: block;
  width: 25px;
  height: 3px;
  margin: 5px auto;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background-color: #101010;
}

.nav_menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav_item {
  margin-left: 3rem;
}

.nav_link {
  color: #475569;
  cursor: pointer;
  text-decoration: none;
}

.search_container {
  cursor: pointer;
  margin-left: 1rem;
  padding: 0.3rem 0.5rem;
  border-radius: 0.5rem;
  border: 1px solid #000a45;
}

.search_container > img {
  width: 1rem;
}

.login_button {
  cursor: pointer;
  margin: 0 1rem;
  color: #ffff;
  border: none;
  border-radius: 0.5rem;
  padding: 0.5rem 1rem;
  background-color: #000a45;
  font-family: "poppinsRegular";
}
.login_button_side {
  color: #000a45;
  border: none;
  border-radius: 0.5rem;
  padding: 0.5rem 1rem;
  font-family: "poppinsRegular";
  background-color: #ffff;
}
.nav_link:hover {
  color: #00b4d8;
  font-weight: bold;
}

.nav_logo {
  width: 25rem;
  transform: translate(15%, 0%);
}

ul li {
  list-style-type: none;
}

.footerLogo {
  display: none;
}

@media only screen and (max-width: 768px) {
  .header {
    height: 4rem;
  }
  .nav_menu {
    position: fixed;
    left: -120%;
    top: -1rem;
    flex-direction: column;

    width: 100%;
    text-align: center;
    transition: 0.5s;
    /* box-shadow: 0 10px 27px rgba(0, 0, 0, 0.05); */
  }

  .nav_menu {
    justify-content: center;
  }
  .active {
    /* left: -2rem !important; */
    background-color: #000a45;
    z-index: 2;
    height: 100%;
  }

  .white {
    background-color: #ffff;
  }

  .nav_link {
    color: #ffff;
    font-weight: bold;
  }

  .nav_item {
    margin: 1.5rem 0;
  }

  .nav_logo {
    width: 13rem;
    /* padding: 0.5rem 0; */
    transform: translate(10%, 10%);
  }

  .hamburger {
    display: block;
    cursor: pointer;
  }
  .nav_link {
    color: white;
    font-weight: lighter !important;
  }

  .nav_link_active {
    color: white !important;
    text-decoration: none !important;
    font-weight: bold;
  }
  .search_container {
    display: none;
  }

  .hamburger.active .bar:nth-child(2) {
    opacity: 0;
  }

  .hamburger.active .bar:nth-child(1) {
    transform: translateY(8px) rotate(45deg);
  }

  .hamburger.active .bar:nth-child(3) {
    transform: translateY(-8px) rotate(-45deg);
  }

  .footerLogo {
    display: block;
    width: 6rem;
    margin-top: 5rem;
  }

  .search_container_mobile {
    position: absolute;
    right: 4rem;
    border: 1px solid #000a45;
    padding: 0.2rem 0.4rem;
    border-radius: 0.5rem;
  }
  .search_container_mobile > img {
    width: 0.8rem;
  }
}

.active {
  left: -2rem;
  color: #000a45;
  z-index: 2;
  height: 100%;
}

.innActive {
  left: "none" !important;
  z-index: 2;
  height: 100%;
}


.nav_link {
  text-decoration: none !important;
}

.nav_link_active {
  color: #000a45;
  text-decoration: none !important;
  font-weight: bolder;
}